import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BusinessProfileService } from 'src/services/business-profile.service';
import { FileUploadService } from 'src/services/file-upload.service';

declare var jQuery: any;

@Component({
  selector: 'app-showrooms',
  templateUrl: './showrooms.component.html',
  styleUrls: ['./showrooms.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ShowroomsComponent implements OnInit {
  USERSFILESURL: string = environment.USERSFILESURL;
  FILESURL: string = environment.FILESURL;

  currentUser: any = null;

  allShowrooms: any[] = [];
  filterShowrooms: any[] = [];

  pageShowrooms: any[] | null = [];

  itemsPerPage: number = 12;
  pageCount: number = 0;
  currentPage: number = 1;

  filterApplied: boolean = false;
  locationSelectedItem: any;

  nameFilter: string = "";

  brands: any[] = [];
  branSelectedItem: any;
  filterBrands: any[] = [];

  conditionSelectedItem: any;

  randomQueryParam = `?v=${Math.random().toString(36).substring(7)}`;

  imagesKey: string[] = [];

  pageImages: any = {};

  constructor(private businessProfileService: BusinessProfileService, private fileUploadService: FileUploadService) {
    this.currentUser = JSON.parse(localStorage.getItem('user')!);
  }

  async ngOnInit(): Promise<void> {
    document.querySelector(".preloader")?.removeAttribute("style");

    let selectedCity = localStorage.getItem('city') ?? "All";

    this.allShowrooms = await this.businessProfileService.getBunissProfileByTypeAsync(3, -1, -1, selectedCity != "All" ? [{ "key": "location", "value": selectedCity }] : null);

    this.imagesKey = [...this.imagesKey, ...this.allShowrooms.map(b => "usersfiles/" + b.businessid + b.businessimagepath1)];

    if (this.allShowrooms && this.allShowrooms.length > 0) {
      let promotedShowrooms: any[] = this.allShowrooms.filter((a) => a.isPromoted);
      promotedShowrooms = promotedShowrooms.sort((a, b) => b.businessid - a.businessid);

      let otherShowrooms: any[] = this.allShowrooms.filter((a) => !a.isPromoted);
      otherShowrooms = otherShowrooms.sort((a, b) => b.businessid - a.businessid);

      this.allShowrooms = promotedShowrooms.concat(otherShowrooms);

      /**,
       "Motorcycle",
       "ATV/UTV",
       "Scooter",
       "Personal Watercraft",
       "Golf Cart",
       "Heavy Vehicle" */
      this.brands = await this.businessProfileService.getBransByTypesAsync(["Car"]);

      this.brands = this.brands.filter(b => b.brandname != "All");

      this.filterBrands = this.brands;
    }

    this.filterShowrooms = this.allShowrooms;

    if (this.filterShowrooms) {
      const quotient = Math.floor(this.filterShowrooms.length / this.itemsPerPage);
      const remainder = this.filterShowrooms.length % this.itemsPerPage;

      this.pageCount = quotient + (remainder > 0 ? 1 : 0);

      this.pageShowrooms = this.paginate(this.filterShowrooms, this.currentPage, this.itemsPerPage);
    }

    let _self = this;
    setTimeout(() => {
      (function ($) {
        $('#list_pagination').niceSelect();

        $('#list_pagination').on("change", (e: any) => {
          _self.goToPage(e);
        });
      })(jQuery);
    }, 100);

    try {
      this.pageImages = await this.fileUploadService.getBulkPresignedUrls(this.imagesKey);
    } catch (error) {

    }

    document.querySelector(".preloader")?.setAttribute("style", "display: none;");
  }

  geoToDetails(event: any, showroom: any) {
    let ctrl = event.target;

    if (ctrl.getAttribute("name") != "location")
      window.location.href = "business/showrooms/details/" + showroom.businessid;
  }

  paginate(array: any[], pageNumber: number, itemsPerPage: number) {
    var begin = ((pageNumber - 1) * itemsPerPage);
    var end = begin + itemsPerPage;
    return array.slice(begin, end);
  }

  goToNextPage() {
    if (this.filterShowrooms && this.currentPage < this.pageCount) {
      this.currentPage++;

      this.pageShowrooms = this.paginate(this.filterShowrooms, this.currentPage, this.itemsPerPage);

      let _self = this;
      (function ($) {
        $('#list_pagination').val(_self.currentPage);

        $('#list_pagination').niceSelect('update');

        $("html, body").animate({ scrollTop: 0 }, 500);
        return false;
      })(jQuery);
    }
  }

  goToPage(event: any) {
    let page = parseInt(event.target.value);
    if (this.filterShowrooms) {
      this.currentPage = page;

      this.pageShowrooms = this.paginate(this.filterShowrooms, this.currentPage, this.itemsPerPage);

      (function ($) {
        $("html, body").animate({ scrollTop: 0 }, 500);
        return false;
      })(jQuery);
    }
  }

  goToPreviousPage() {
    if (this.filterShowrooms && this.currentPage > 1) {
      this.currentPage--;

      this.pageShowrooms = this.paginate(this.filterShowrooms, this.currentPage, this.itemsPerPage);

      let _self = this;
      (function ($) {
        $('#list_pagination').val(_self.currentPage);

        $('#list_pagination').niceSelect('update');

        $("html, body").animate({ scrollTop: 0 }, 500);
        return false;
      })(jQuery);
    }
  }

  setNameFilter(event: any) {
    var val = event.target.value;
    this.nameFilter = val.trim();
  }

  clearNameFilter(val: any) {
    (<HTMLInputElement>document.getElementById("namefilter")).value = "";
    this.nameFilter = "val";
  }

  locationChanged(location: any) {
    if (location)
      this.locationSelectedItem = location;
    else
      this.locationSelectedItem = null;
  }

  clearSelectionLoaction() {
    this.locationSelectedItem = null;

    document.getElementsByName("location").forEach(el => {
      (<HTMLInputElement>el).checked = false;
    })
  }

  listFilter(type: string, ctrl: any) {
    let val: string = ctrl.target.value;

    switch (type) {
      case "brand":
        this.filterBrands = this.brands!.filter((m) => {
          return m.brandname.toLowerCase().startsWith(val);
        });
        break;
    }
  }

  brandChanged(brand: any) {
    if (brand) {
      this.branSelectedItem = {
        "brandid": brand.brandid,
        "brandname": brand.brandname
      };
    } else {
      this.branSelectedItem = null;
    }
  }

  clearSelectionBrand() {
    this.branSelectedItem = null;

    document.getElementsByName("brands").forEach(el => {
      (<HTMLInputElement>el).checked = false;
    })
  }

  conditionChanged(condition: any) {
    if (condition) {
      this.conditionSelectedItem = condition;
    } else {
      this.conditionSelectedItem = null;
    }
  }

  clearSelectionCondition() {
    this.conditionSelectedItem = null;

    document.getElementsByName("conditions").forEach(el => {
      (<HTMLInputElement>el).checked = false;
    })
  }

  applyFilter() {
    this.filterApplied = false;

    this.filterShowrooms = this.allShowrooms;

    if (this.nameFilter != "") {
      this.filterShowrooms = this.filterShowrooms!.filter((a: any) => {
        return a.businessname.toLowerCase().indexOf(this.nameFilter.toLowerCase()) >= 0;
      });

      this.filterApplied = true;
    }

    if (this.locationSelectedItem) {
      this.filterShowrooms = this.filterShowrooms!.filter((a: any) => {
        return a.businesscity.toLowerCase() == this.locationSelectedItem.toLowerCase();
      });

      this.filterApplied = true;
    }

    if (this.branSelectedItem) {
      var id = parseInt(this.branSelectedItem.brandid);
      this.filterShowrooms = this.filterShowrooms.filter((a: any) => {
        for (const b of a.businessbrands) {
          if (id == b.brandid) {
            return true;
          }
        }
        return false;
      });

      this.filterApplied = true;
    }

    if (this.conditionSelectedItem) {
      this.filterShowrooms = this.filterShowrooms!.filter((a: any) => {
        return a.autocondition == this.conditionSelectedItem || a.autocondition == 3;
      });

      this.filterApplied = true;
    }

    const quotient = Math.floor(this.filterShowrooms.length / this.itemsPerPage);
    const remainder = this.filterShowrooms.length % this.itemsPerPage;

    this.pageCount = quotient + (remainder > 0 ? 1 : 0);

    this.currentPage = 1;

    this.pageShowrooms = this.paginate(this.filterShowrooms!, this.currentPage, this.itemsPerPage);

    let _self = this;
    setTimeout(() => {
      (function ($) {
        $('#list_pagination').niceSelect('destroy');

        $('#list_pagination').val(_self.currentPage);

        $('#list_pagination').niceSelect();
      })(jQuery);
    }, 100);
  }

  clearFilter() {
    if (this.filterApplied) {
      this.filterShowrooms = this.allShowrooms;

      const quotient = Math.floor(this.filterShowrooms.length / this.itemsPerPage);
      const remainder = this.filterShowrooms.length % this.itemsPerPage;

      this.pageCount = quotient + (remainder > 0 ? 1 : 0);

      this.currentPage = 1;

      this.pageShowrooms = this.paginate(this.filterShowrooms!, this.currentPage, this.itemsPerPage);

      this.clearSelectionLoaction();

      this.clearSelectionBrand();

      this.clearSelectionCondition();

      this.filterApplied = false;
    }

    this.filterBrands = this.brands;

    (<HTMLInputElement>document.getElementById("namefilter")).value = "";
    (<HTMLInputElement>document.getElementById("brandfilter")).value = "";

    let _self = this;
    setTimeout(() => {
      (function ($) {
        $('#list_pagination').niceSelect('destroy');

        $('#list_pagination').val(_self.currentPage);

        $('#list_pagination').niceSelect();
      })(jQuery);
    }, 100);
  }
}
