// import { AgmMap, MapsAPILoader } from '@agm/core';
import { Loader } from '@googlemaps/js-api-loader';
import { Component, ElementRef, NgZone, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Select2OptionData } from 'ng-select2';
import { NgxImageCompressService } from 'ngx-image-compress';
import { catchError, throwError } from 'rxjs';
import { Options } from 'select2';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from 'src/services/authentication.service';
import { AutoService } from 'src/services/auto.service';
import { BusinessProfileService } from 'src/services/business-profile.service';
import { DynamicScriptLoaderService } from 'src/services/dynamic-script-loader.service';
import { FileUploadService } from 'src/services/file-upload.service';

declare var jQuery: any;

interface BusinessProfile {
  businessid?: number | null;
  tradelicensenumber?: string | null;
  tradelicensecity?: string | null;
  businessname?: string | null;
  businessdescription?: string | null;
  businessnamear?: string | null;
  businessdescriptionar?: string | null;
  businessemail?: string | null;
  countrycode?: string | null;
  businessmobilenumber?: string | null;
  businesscontactnumber?: string | null;
  businesssecondarymobilenumber?: string | null;
  businesswhatsappnumber?: string | null;
  businesslat?: number | null;
  businesslng?: number | null;
  businessaddress?: string | null;
  businesscountry?: string | null;
  businesscity?: string | null;
  businesstrademark?: number | null;
  businesslogopath?: string | null;
  businesslicensepath?: string | null;
  businessimagepath1?: string | null;
  businessimagepath2?: string | null;
  businessimagepath3?: string | null;
  businessimagepath4?: string | null;
  tradelicensecopypath?: string | null;
  businesswebsite?: string | null;
  businessibannumber?: string | null;
  businessbankname?: string | null;
  businessbankbeneficiaryname?: string | null;
  termsandconditions?: string | null;
  acceptcashondelivery?: number | null;
  businessactivitytype?: number | null;
  businessdeliverychannel?: number | null;
  appointment?: number | null;
  EnginePetrol?: number | null;
  EngineDiesel?: number | null;
  EngineHybrid?: number | null;
  EngineElectrical?: number | null;
  conditionnewgenuine?: number | null;
  conditionnewoem?: number | null;
  conditionnewaftermarket?: number | null;
  conditionusedgenuine?: number | null;
  conditionusedoem?: number | null;
  autocondition?: number | null;
  deliverycharges?: number | null;
  creationdate?: Date | null;
  businessstatus?: number | null;
  userid?: number | null;
  businessstarrating?: number | null;
  subscriptionplanid?: number | null;
  subscriptionplanexpiry?: number | null;
  promotedplanid?: number | null;
  promotedplanexpiry?: number | null;
}

interface BusWorkHours {
  dayofweek?: number | null;
  isweekend?: number | null;
  starttime?: string | null;
  closetime?: string | null;
  breakstarttime?: string | null;
  breakendtime?: string | null;
}

@Component({
  selector: 'app-edit-business',
  templateUrl: './edit-business.component.html',
  styleUrls: ['./edit-business.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class EditBusinessComponent implements OnInit {
  USERSFILESURL: string = environment.USERSFILESURL;
  FILESURL: string = environment.FILESURL;

  currentUser: any = null;

  businessid: number | null = null;
  currentBusiness: any | null = null;
  autoServicesImages: any[] = [];

  isValid: boolean = false;

  businessactivity: number = -1;

  option: string[] = [
    "Auto Services",
    "Auto Spare Parts",
    "Auto Showrooms",
    "Auto Tools",
  ];

  locations: string[] = [
    "Abu Dhabi",
    "Al Ain",
    "Dubai",
    "Sharjah",
    "Ajman",
    "Umm Al-Quwain",
    "Fujairah",
    "Ras Alkhaima",
  ];

  weekDay: string[] = [
    "Mon",
    "Tue",
    "Wed",
    "Thu",
    "Fri",
    "Sat",
    "Sun",
  ];

  is24Hours: boolean = false;
  canSetSameTime: boolean = false;

  hours: any[] = [
    {
      "value": 1,
      "text": "07:00 AM",
    },
    {
      "value": 2,
      "text": "07:30 AM",
    },
    {
      "value": 3,
      "text": "08:00 AM",
    },
    {
      "value": 4,
      "text": "08:30 AM",
    },
    {
      "value": 5,
      "text": "09:00 AM",
    },
    {
      "value": 6,
      "text": "09:30 AM",
    },
    {
      "value": 7,
      "text": "10:00 AM",
    },
    {
      "value": 8,
      "text": "10:30 AM",
    },
    {
      "value": 9,
      "text": "11:00 AM",
    },
    {
      "value": 10,
      "text": "11:30 AM",
    },
    {
      "value": 11,
      "text": "12:00 PM",
    },
    {
      "value": 13,
      "text": "12:30 PM",
    },
    {
      "value": 14,
      "text": "01:00 PM",
    },
    {
      "value": 15,
      "text": "01:30 PM",
    },
    {
      "value": 16,
      "text": "02:00 PM",
    },
    {
      "value": 17,
      "text": "02:30 PM",
    },
    {
      "value": 18,
      "text": "03:00 PM",
    },
    {
      "value": 19,
      "text": "03:30 PM",
    },
    {
      "value": 20,
      "text": "04:00 PM",
    },
    {
      "value": 21,
      "text": "04:30 PM",
    },
    {
      "value": 22,
      "text": "05:00 PM",
    },
    {
      "value": 23,
      "text": "05:30 PM",
    },
    {
      "value": 24,
      "text": "06:00 PM",
    },
    {
      "value": 25,
      "text": "06:30 PM",
    },
    {
      "value": 26,
      "text": "07:00 PM",
    },
    {
      "value": 27,
      "text": "07:30 PM",
    },
    {
      "value": 28,
      "text": "08:00 PM",
    },
    {
      "value": 29,
      "text": "08:30 PM",
    },
    {
      "value": 30,
      "text": "09:00 PM",
    },
    {
      "value": 31,
      "text": "09:30 PM",
    },
    {
      "value": 32,
      "text": "10:00 PM",
    },
    {
      "value": 33,
      "text": "10:30 PM",
    },
    {
      "value": 34,
      "text": "11:00 PM",
    },
    {
      "value": 35,
      "text": "11:30 PM",
    },
    {
      "value": 36,
      "text": "12:00 AM",
    },
    {
      "value": 37,
      "text": "12:30 AM",
    },
    {
      "value": 38,
      "text": "01:00 AM",
    },
    {
      "value": 38,
      "text": "01:30 AM",
    },
    {
      "value": 39,
      "text": "02:00 AM",
    },
    {
      "value": 40,
      "text": "02:30 AM",
    },
    {
      "value": 41,
      "text": "03:00 AM",
    },
    {
      "value": 42,
      "text": "03:30 AM",
    },
    {
      "value": 43,
      "text": "04:00 AM",
    },
    {
      "value": 44,
      "text": "04:30 AM",
    },
    {
      "value": 45,
      "text": "05:00 AM",
    },
    {
      "value": 46,
      "text": "05:30 AM",
    },
    {
      "value": 47,
      "text": "06:00 AM",
    },
    {
      "value": 48,
      "text": "06:30 AM",
    },
  ];

  engineType = ["Petrol", "Diesel", "Hybrid", "Electric"];

  deliveryChannel = ["Doorstep", "On Premises"];

  newCons = ["Genuine", "OEM", "After Market"];
  useCons = ["Genuine", "OEM"];

  allVehicleTypes: any[] | null = [];
  vehicleTypes: any[] | null = [];
  selectedVehicletype: string = "";

  defaultBounds: any;
  componentRestrictions: any;
  latitude: number = 25.192118;
  longitude: number = 55.279468;
  zoom = 16;
  currentLocation: any;
  country: any;
  city: any;

  // @ViewChild(AgmMap, { static: true }) public agmMap!: AgmMap;
  @ViewChild('search') public searchElementRef!: ElementRef;

  errorsList: string[] = [];

  Categories: any[] = [];
  selectedCatrgories: any[] = [];

  businessProfile: BusinessProfile = {
    businessid: null,
    tradelicensenumber: null,
    tradelicensecity: null,
    businessname: null,
    businessdescription: null,
    businessnamear: null,
    businessdescriptionar: null,
    businessemail: null,
    countrycode: "00971",
    businessmobilenumber: null,
    businesscontactnumber: null,
    businesssecondarymobilenumber: null,
    businesswhatsappnumber: null,
    businesslat: null,
    businesslng: null,
    businessaddress: null,
    businesscountry: null,
    businesscity: null,
    businesstrademark: null,
    businesslogopath: null,
    businesslicensepath: null,
    businessimagepath1: null,
    businessimagepath2: null,
    businessimagepath3: null,
    businessimagepath4: null,
    tradelicensecopypath: "",
    businesswebsite: null,
    businessibannumber: null,
    businessbankname: null,
    businessbankbeneficiaryname: null,
    termsandconditions: null,
    acceptcashondelivery: null,
    businessactivitytype: null,
    businessdeliverychannel: 0,
    appointment: null,
    EnginePetrol: 0,
    EngineDiesel: 0,
    EngineHybrid: 0,
    EngineElectrical: 0,
    conditionnewgenuine: 0,
    conditionnewoem: 0,
    conditionnewaftermarket: 0,
    conditionusedgenuine: 0,
    conditionusedoem: 0,
    autocondition: 0,
    deliverycharges: 0,
    creationdate: null,
    businessstatus: 2,
    userid: null,
    businessstarrating: 0,
    subscriptionplanid: null,
    subscriptionplanexpiry: null,
    promotedplanid: null,
    promotedplanexpiry: null,
  }

  busWorkHours: (BusWorkHours | null)[] = new Array<BusWorkHours | null>(7);

  businessLogoFile: any;
  coverFile: any;
  otherFiles: any[] = [];
  businessLicenseFile: any;
  files: any[] = [];
  // businessImageFiles: any[] = [];
  // files: any[] = [];

  brandsList: Array<Select2OptionData> = [];
  options: Options = {
    multiple: true,
    closeOnSelect: false,
    templateSelection: (object: any) => {
      return object && object.id && object.text;
    },
    templateResult: (object: any) => {
      return object && object.id && object.text;
    }
  };
  brandSelectedItems: any[] = [];
  brandValues: any[] = [];
  // get brandSelectedItems(): any[] {
  //   return this._brandSelectedItems;
  // }
  // set brandSelectedItems(value: any[]) {
  //   this._brandSelectedItems = value;
  // }

  logoUpdated: boolean = false;
  coverUpdate: boolean = false;
  otherImagesUpdated: boolean = false;
  licenseUpdated: boolean = false;

  map: google.maps.Map | null = null;
  marker: google.maps.marker.AdvancedMarkerElement | null = null;

  additionalOptions = {
    center: {
      lat: 25.192118,
      lng: 55.279468
    },
    zoom: 16
  };
  // [START maps_programmatic_load_promise]
  loader = new Loader({
    apiKey: "AIzaSyA-ZAjwV7EB0quFqkKZzlz2JWFeAqxhAEY",
    version: "weekly",
    libraries: ["places", "maps", "marker"],
    ...this.additionalOptions,
  });

  //private apiloader: MapsAPILoader,

  constructor(private route: ActivatedRoute, private businessProfileService: BusinessProfileService, private fileUploadService: FileUploadService, private autoService: AutoService, private authenticationService: AuthenticationService, 
    private ngZone: NgZone, private imageCompress: NgxImageCompressService, private dynamicScriptLoader: DynamicScriptLoaderService) {
    this.currentUser = JSON.parse(localStorage.getItem('user')!);

    this.busWorkHours[0] = null;
    this.busWorkHours[1] = null;
    this.busWorkHours[2] = null;
    this.busWorkHours[3] = null;
    this.busWorkHours[4] = null;
    this.busWorkHours[5] = null;
    this.busWorkHours[6] = null;

    let id = this.route.snapshot.paramMap.get('id');

    if (id)
      this.businessid = parseInt(id);
  }

  async loadData() {
    if (this.businessid) {
      this.currentBusiness = await this.businessProfileService.getBusinessprofileByidAsync(this.businessid, false);

      if (!this.currentBusiness)
        window.location.href = "home";

      // for (let i = 1; i < 5; i++) {
      //   if (this.currentBusiness["businessimagepath" + i.toString()]) {
      //     this.autoServicesImages.push(this.currentBusiness["businessimagepath" + i.toString()]);
      //   }
      // }

      Object.keys(this.businessProfile).forEach(key => {
        (<any>this.businessProfile)[key] = this.currentBusiness[key];
      });

      // let specCtrls = <NodeListOf<HTMLInputElement>>document.querySelectorAll('input[name="businessactivity"]');

      // specCtrls.forEach((e, i) => {
      //   if (e.value == (this.businessProfile.businessactivitytype! - 1).toString())
      //     e.checked = true;
      // });

      this.latitude = this.businessProfile.businesslat!;
      this.longitude = this.businessProfile.businesslng!;

      await this.getBusinessData(this.businessProfile.businessactivitytype!);

      this.is24Hours = (<any[]>this.currentBusiness.businessworkinghours)
        .filter((wh: any) =>
          wh["starttime"] == "00:00:00" &&
          wh["closetime"] == "00:00:00")
        .length ==
        7;

      (<any[]>this.currentBusiness.businessworkinghours).forEach((wh: any, i: number) => {
        this.busWorkHours[i] = {
          dayofweek: wh.dayofweek,
          starttime: this.getSelectFromTime(wh.starttime),
          closetime: this.getSelectFromTime(wh.closetime),
          breakstarttime: this.getSelectFromTime(wh.breakstarttime),
          breakendtime: this.getSelectFromTime(wh.breakendtime),
          isweekend: wh.isweekend
        };
      });

      if (!this.is24Hours) {
        let _self = this;

        this.dynamicScriptLoader.load("main");

        (function ($) {
          for (let index = 0; index < 7; index++) {
            const ddlFrom = $("#ddl_from_" + index);
            const ddlTo = $("#ddl_to_" + index);
            const ddlBFrom = $("#ddl_bfrom_" + index);
            const ddlBTo = $("#ddl_bto_" + index);

            let startValue = _self.hours.find(h => h.text == _self.busWorkHours[index]?.starttime);
            let endValue = _self.hours.find(h => h.text == _self.busWorkHours[index]!?.closetime);
            let startBValue = _self.hours.find(h => h.text == _self.busWorkHours[index]!?.breakstarttime);
            let endBValue = _self.hours.find(h => h.text == _self.busWorkHours[index]!?.breakendtime);

            if (startValue)
              ddlFrom.val(startValue.value);
            if (endValue)
              ddlTo.val(endValue.value);
            if (startBValue)
              ddlBFrom.val(startBValue.value);
            if (endBValue)
              ddlBTo.val(endBValue.value);

            $('.select').niceSelect('update');
          }
        })(jQuery);
      }

      if (this.currentBusiness.businesscategories) {
        this.selectedCatrgories = this.currentBusiness.businesscategories;

        let sCatrgories = (<any[]>this.currentBusiness.businesscategories).map((c: any) => {
          return {
            "categoryid": c.categoryid,
            "categoryname": c.categoryname
          }
        });

        document.querySelectorAll(".business-category").forEach((s) => {
          let ex = sCatrgories?.find((c: any) => c.categoryid.toString() == (s as HTMLDivElement).getAttribute("category"));

          if (ex) {
            this.selectCategory(s, false);
            // (s as HTMLDivElement).click();
            return;
          }
        });
      }

      if (this.currentBusiness.businessbrands) {
        this.brandSelectedItems = this.currentBusiness.businessbrands;

        let bodyStyle = this.currentBusiness.businessbrands[0]["brandtype"];

        this.brandValues = (<any[]>this.currentBusiness.businessbrands).map(b => b.brandid.toString());

        setTimeout(() => {
          document.querySelectorAll(".vehicle-type").forEach((s) => {
            if (bodyStyle == (s as HTMLDivElement).getAttribute("vtype")) {
              this.selectVehicleType(s);
              return;
            }
          });

          this.isValid = true;
        }, 50);
      }
    }
  }

  getPhoneNumber(phone?: string | null | undefined) {
    if (phone) {
      if (phone.startsWith("971"))
        return phone.substring(3);
      else
        return phone;
    }
    return "";
  }

  isChecked(type: string, index: number) {
    if (type == "engineType")
      switch (index) {
        case 0:
          return this.businessProfile.EnginePetrol == 1;
        case 1:
          return this.businessProfile.EngineDiesel == 1;
        case 2:
          return this.businessProfile.EngineHybrid == 1;
        case 3:
          return this.businessProfile.EngineElectrical == 1;
      }
    else if (type == "deliveryChannel")
      switch (index) {
        case 0:
          return this.businessProfile.businessdeliverychannel == 1 || this.businessProfile.businessdeliverychannel == 3
        case 1:
          return this.businessProfile.businessdeliverychannel == 2 || this.businessProfile.businessdeliverychannel == 3
      }
    else if (type == "conditions")
      switch (index) {
        case 0:
          return this.businessProfile.autocondition == 1 || this.businessProfile.autocondition == 3
        case 1:
          return this.businessProfile.autocondition == 2 || this.businessProfile.autocondition == 3
      }
    else if (type == "newCons")
      switch (index) {
        case 0:
          return this.businessProfile.conditionnewgenuine == 1;
        case 1:
          return this.businessProfile.conditionnewoem == 1;
        case 2:
          return this.businessProfile.conditionnewaftermarket == 1;
      }
    else if (type == "useCons")
      switch (index) {
        case 0:
          return this.businessProfile.conditionusedgenuine == 1;
        case 1:
          return this.businessProfile.conditionusedoem == 1;
      }
    return false;
  }

  async ngOnInit(): Promise<void> {
    if (!(this.currentUser && (this.currentUser.isadmin)))
      window.location.href = "home";
    else {
      await this.loadData();

      this.defaultBounds = {
        north: 51.333232,
        south: 22.452164,
        east: 57.145000,
        west: 26.441565,
      };

      this.componentRestrictions = {
        latLngBounds: {
          north: 51.333232,
          south: 22.452164,
          east: 57.145000,
          west: 26.441565,
        },
        strictBounds: true,
      }
    }

    setTimeout(() => {
      (function ($) {

        $(document).on('change', '.select', function (e: any) {
          e.target.dispatchEvent(new Event('change'));
        });

      })(jQuery);

      document.querySelector(".preloader")?.setAttribute("style", "display: none;");
    }, 50);

    try {
      this.mapLoad();

      // this.get()
      // this.zoom = 16;
      // if (this.agmMap)
      //   this.agmMap!.triggerResize(true);
    } catch (error) {
      console.log(error);

    }
  }

  async getBusinessData(type: number) {
    document.querySelector(".only-lock")?.removeAttribute("style");

    this.businessactivity = type;

    switch (type) {
      case 1:
      case 2:
        this.Categories = await this.businessProfileService.getAllCategoriesAsync(type);

        this.allVehicleTypes = await this.autoService.getAllVehicleBodyTypeAsync();

        this.vehicleTypes = this.autoService.identifyDuplicates(this.allVehicleTypes!, "vehicletypeen");
        break;
      case 3:
        this.allVehicleTypes = await this.autoService.getAllVehicleBodyTypeAsync();

        this.vehicleTypes = this.autoService.identifyDuplicates(this.allVehicleTypes!, "vehicletypeen");
        break;
      case 4:
        this.Categories = await this.businessProfileService.getAllCategoriesAsync(type);

        let brands: any[] = await this.businessProfileService.getBransByTypesAsync(["Tool"]);

        var all = brands.find((b: any) => b["brandname"] == "All");

        if (all != null) {
          let index = brands.indexOf(all);
          brands.splice(index, 1);

          brands.splice(0, 0, all);
        }

        this.brandsList = brands.map((m: any) => {
          return {
            id: m.brandid,
            text: m.brandname,
            additional: {
              brandcode: m.brandcode,
              brandnamear: m.brandnamear,
              brandtype: m.brandtype,
              brandlogopath: m.brandlogopath
            }
          };
        });
        break;
    }

    (function ($) {
      $(document).on('select2:open', () => {
        (<HTMLInputElement>document.querySelector('.select2-search__field')).focus();
      });
    })(jQuery);

    document.querySelector(".only-lock")?.setAttribute("style", "display: none;");
  }

  fixCategoryName(name: string) {
    return name.replaceAll(/\/n\/r/g, '');
  }

  async selectCategory(event: any, checkExsit: boolean = true) {
    let element = event.target ? event.target as HTMLElement : event as HTMLElement;

    let div = element;

    let img: HTMLImageElement;
    let span: HTMLSpanElement;

    if (element.tagName.toLowerCase() == "img" || element.tagName.toLowerCase() == "span") {
      div = element.parentNode as HTMLElement;
    }

    img = div.firstChild as HTMLImageElement;
    span = div.children[1] as HTMLSpanElement;

    let categoryid = div.getAttribute("category");

    let exist = this.selectedCatrgories!.find((c: any) => c.categoryid == categoryid);

    if (exist && checkExsit) {
      let index = this.selectedCatrgories.indexOf(exist);
      this.selectedCatrgories.splice(index, 1);

      div.classList.remove("border-danger");
      img!.src = img!.src.replace("-%20red.svg", "-%20gray.svg");
      span!.classList.remove("text-danger");
    } else {
      let category = this.Categories!.find((c: any) => c.categoryid == categoryid);
      if (category) {
        let ct = this.selectedCatrgories!.find((c: any) => c.categoryid == categoryid);
        if (!ct)
          this.selectedCatrgories.push({
            "categoryid": category.categoryid,
            "categoryname": category.categoryname
          });

        div.classList.add("border-danger");
        img!.src = img!.src.replace("-%20gray.svg", "-%20red.svg");
        span!.classList.add("text-danger");
      }
    }
  }

  async selectVehicleType(event: any) {
    let element = event.target ? event.target as HTMLElement : event as HTMLElement;

    this.brandSelectedItems = [];

    let div = element;

    let img: HTMLImageElement;
    let span: HTMLSpanElement;

    if (element.tagName.toLowerCase() == "img" || element.tagName.toLowerCase() == "span") {
      div = element.parentNode as HTMLElement;
    }

    let type = div.getAttribute("vtype");

    let vType = this.vehicleTypes!.find((v: any) => v.vehicletypeen == type);
    if (vType) {
      this.selectedVehicletype = vType.vehicletypeen;

      await this.getBrands();

      // if (this.selectedVehicletype == "Car" || this.selectedVehicletype == "Heavy Vehicle")
      //   this.vehicleBodyTypes = this.allVehicleTypes!.filter((t: any) => t.vehicletypeen == this.selectedVehicletype);
      // else
      //   this.vehicleBodyTypes = [];
    }

    img = div.firstChild as HTMLImageElement;
    span = div.children[1] as HTMLSpanElement;

    document.querySelectorAll('.vehicle-type').forEach((d) => {
      d.classList.remove("border-danger");

      let i = d.firstChild as HTMLImageElement;
      let s = d.children[1] as HTMLSpanElement;

      i.src = i.src.replace("-red.svg", ".svg");
      s.classList.remove("text-danger");
    });

    div.classList.add("border-danger");
    img!.src = img!.src.replace(".svg", "-red.svg");
    span!.classList.add("text-danger");

    (function ($) {
      $(document).on('select2:open', () => {
        (<HTMLInputElement>document.querySelector('.select2-search__field')).focus();
      });
    })(jQuery);
  }

  async getBrands() {
    document.querySelector(".only-lock")?.removeAttribute("style");

    let brands: any[] = await this.businessProfileService.getBransByTypesAsync([this.selectedVehicletype]);

    var all = brands.find((b: any) => b["brandname"] == "All");

    if (all != null) {
      let index = brands.indexOf(all);
      brands.splice(index, 1);

      brands.splice(0, 0, all);
    }

    this.brandsList = brands.map((m: any) => {
      return {
        id: m.brandid,
        text: m.brandname,
        additional: {
          brandcode: m.brandcode,
          brandnamear: m.brandnamear,
          brandtype: m.brandtype,
          brandlogopath: m.brandlogopath
        }
      };
    });

    document.querySelector(".only-lock")?.setAttribute("style", "display: none;");
  }

  brandChange(event: any) {
    if (event) {
      this.brandSelectedItems = this.brandsList.filter((b: any) => event.includes(b.id.toString())).map(b => {
        return {
          "brandid": b.id,
          "brandname": b.text,
          "brandnamear": b.additional.brandnamear,
          "brandcode": b.additional.brandcode,
          "brandtype": b.additional.brandtype,
          "brandlogopath": b.additional.brandlogopath
        };
      });
    }
  }

  async getExsitBusiness() {
    document.querySelector(".only-lock")?.removeAttribute("style");

    let locationsCtrl = <HTMLSelectElement>document.getElementById("ddl_locations");
    let tradeNumberCtrl = <HTMLInputElement>document.getElementById("txt_tradeNumber");
    let bus = await this.businessProfileService.getBusinessprofileAsync(tradeNumberCtrl.value, locationsCtrl.value);

    document.querySelector(".only-lock")?.setAttribute("style", "display: none;");

    return bus != null;
  }

  async tradeNumberBtnClick(event: any) {
    await this.validate('tradeNumber');
    this.tapChange(event, 'info', 'tradeNumber')
  }

  validateEmail(value: string) {
    let pattern = "^[a-zA-Z0-9.a-zA-Z0-9.!#$%&'*+-/=?^_`{|}~]+@[a-zA-Z0-9-_]+\.[a-zA-Z]+";
    let regex = new RegExp(pattern);
    return (!regex.test(value)) ? false : true;
  }

  async validate(formname: string) {
    this.errorsList = [];
    switch (formname) {
      case "businessactivity":
        let specCtrl = <HTMLInputElement>document.querySelector('input[name="businessactivity"]:checked');
        if (!specCtrl)
          this.errorsList.push("Select Business Activity Type");
        else
          this.businessProfile.businessactivitytype = parseInt(specCtrl.value) + 1
        break;
      case "tradeNumber":
        let locationsCtrl = <HTMLSelectElement>document.getElementById("ddl_locations");
        let tradeNumberCtrl = <HTMLInputElement>document.getElementById("txt_tradeNumber");
        if (locationsCtrl.value.trim() == "")
          this.errorsList.push("Select Location");

        if (tradeNumberCtrl.value.trim() == "")
          this.errorsList.push("Enter Trade Number");

        if (locationsCtrl.value.trim() != "" && tradeNumberCtrl.value.trim() != "") {
          let exist = await this.getExsitBusiness();

          if (exist)
            this.errorsList.push("Business is already registered");
          else {
            this.businessProfile.tradelicensecity = locationsCtrl.value;
            this.businessProfile.tradelicensenumber = tradeNumberCtrl.value.trim();
          }
        }
        break;
      case "info":
        let businessnameCtrl = <HTMLInputElement>document.getElementById("txt_businessname");
        let businessdescriptionCtrl = <HTMLInputElement>document.getElementById("txt_businessdescription");
        let businessnamearCtrl = <HTMLInputElement>document.getElementById("txt_businessnamear");
        let businessdescriptionarCtrl = <HTMLInputElement>document.getElementById("txt_businessdescriptionar");

        if (businessnameCtrl.value.trim() == "")
          this.errorsList.push("Enter Business Name");
        else
          this.businessProfile.businessname = businessnameCtrl.value;

        if (businessdescriptionCtrl.value.trim() == "")
          this.errorsList.push("Enter Business Description");
        else
          this.businessProfile.businessdescription = businessdescriptionCtrl.value;

        if (businessnamearCtrl.value.trim() == "")
          this.errorsList.push("Enter Business Name (Arabic)");
        else
          this.businessProfile.businessnamear = businessnamearCtrl.value;

        if (businessdescriptionarCtrl.value.trim() == "")
          this.errorsList.push("Enter Business Description (Arabic)");
        else
          this.businessProfile.businessdescriptionar = businessdescriptionarCtrl.value;
        break;
      case "contactinfo":
        let emailCtrl = <HTMLInputElement>document.getElementById("txt_email");
        let mobileCtrl = <HTMLInputElement>document.getElementById("txt_mobile");
        let workCtrl = <HTMLInputElement>document.getElementById("txt_work");
        let smobileCtrl = <HTMLInputElement>document.getElementById("txt_smobile");
        let whatsappCtrl = <HTMLInputElement>document.getElementById("txt_whatsapp");

        if (emailCtrl.value.trim() == "")
          this.errorsList.push("Enter Business Email");
        else if (!this.validateEmail(emailCtrl.value.trim()))
          this.errorsList.push("Enter Correct Business Email");
        else
          this.businessProfile.businessemail = emailCtrl.value;

        if (mobileCtrl.value.trim() == "")
          this.errorsList.push("Enter Business Mobile Number");
        else {
          let mobile = "";

          if (mobileCtrl.value.length == 10 && mobileCtrl.value[0] == "0")
            mobile = mobileCtrl.value.substring(1);
          else
            mobile = mobileCtrl.value;

          if (mobile.length != 9)
            this.errorsList.push("Enter Correct Business Mobile Number");
          else
            this.businessProfile.businessmobilenumber = mobile;
        }

        if (workCtrl.value.trim() == "")
          this.errorsList.push("Enter Business Work Numbe");
        else {
          let phone = "";

          if (workCtrl.value.length == 10 && workCtrl.value[0] == "0")
            phone = workCtrl.value.substring(1);
          else
            phone = workCtrl.value;

          if (phone.length != 9)
            this.errorsList.push("Enter Correct Business Work Number");
          else
            this.businessProfile.businesscontactnumber = workCtrl.value;
        }

        if (smobileCtrl.value.trim() != "") {
          let phone = "";

          if (smobileCtrl.value.length == 10 && smobileCtrl.value[0] == "0")
            phone = smobileCtrl.value.substring(1);
          else
            phone = smobileCtrl.value;

          if (phone.length != 9)
            this.errorsList.push("Enter Correct Business Secondary Mobile Number");
          else
            this.businessProfile.businesssecondarymobilenumber = smobileCtrl.value;
        }

        if (whatsappCtrl.value.trim() != "") {
          let phone = "";

          if (whatsappCtrl.value.length == 10 && whatsappCtrl.value[0] == "0")
            phone = whatsappCtrl.value.substring(1);
          else
            phone = whatsappCtrl.value;

          if (phone.length != 9)
            this.errorsList.push("Enter Correct Business Whatsapp Number");
          else
            this.businessProfile.businesswhatsappnumber = whatsappCtrl.value;
        }
        break;
      case "location":
        let addrCtrl = <HTMLInputElement>document.getElementById("txt_address");
        if (!this.currentLocation)
          this.errorsList.push("Select Location");
        else {
          this.businessProfile.businesslat = this.latitude;
          this.businessProfile.businesslng = this.longitude;
          this.businessProfile.businesscity = this.city;
          this.businessProfile.businesscountry = this.country;
        }
        if (addrCtrl.value.trim() == "")
          this.errorsList.push("Enter Address Details");
        else
          this.businessProfile.businessaddress = addrCtrl.value;
        break;
      case "businessdetails":
        let trademarkownerCtrl = <HTMLInputElement>document.querySelector('input[name="trademarkowner"]:checked');
        if (!this.validatSelectedHours())
          this.errorsList.push("Select Correct working hours");

        if (!trademarkownerCtrl)
          this.errorsList.push("Select Are you Trademark Owner/Attorney/Agency");
        else
          this.businessProfile.businesstrademark = parseInt(trademarkownerCtrl.value);
        break;
      case "images":
        let websiteCtrl = <HTMLInputElement>document.getElementById("txt_website");

        // if (this.businessLogoFile == null)
        //   this.errorsList.push("Select Business/Company Logo");

        // if (!this.coverFile)
        //   this.errorsList.push("Add Cover Photo");
        // if (this.otherFiles.length == 0)
        //   this.errorsList.push("Select Business Images");

        // if (this.businessImageFiles.length == 0)
        //   this.errorsList.push("Select Business Images");

        // if ((this.coverUpdate || this.otherImagesUpdated) && (!this.coverFile || this.otherFiles.length == 0))
        if ((this.otherImagesUpdated) && (!this.coverFile || this.otherFiles.length == 0))
          this.errorsList.push("Select Cover Photo and Business Images");

        if (websiteCtrl.value != "")
          this.businessProfile.businesswebsite = websiteCtrl.value;
        else
          this.businessProfile.businesswebsite = null;
        break;
      case "bankdetails":
        let ibannumberCtrl = <HTMLInputElement>document.getElementById("txt_ibannumber");
        let banknameCtrl = <HTMLInputElement>document.getElementById("txt_bankname");
        let beneficiarynameCtrl = <HTMLInputElement>document.getElementById("txt_beneficiaryname");

        if (ibannumberCtrl.value != "")
          this.businessProfile.businessibannumber = ibannumberCtrl.value;
        else
          this.businessProfile.businessibannumber = null;

        if (banknameCtrl.value != "")
          this.businessProfile.businessbankname = banknameCtrl.value;
        else
          this.businessProfile.businessbankname = null;

        if (beneficiarynameCtrl.value != "")
          this.businessProfile.businessbankbeneficiaryname = beneficiarynameCtrl.value;
        else
          this.businessProfile.businessbankbeneficiaryname = null;
        break;
      case "termsconditions":
        let termsconditionsCtrl = <HTMLInputElement>document.getElementById("txt_termsconditions");
        let acceptcashpaymentCtrl = <HTMLInputElement>document.querySelector('input[name="acceptcashpayment"]:checked');

        if (termsconditionsCtrl.value.trim() != "")
          this.businessProfile.termsandconditions = termsconditionsCtrl.value.trim();

        if (acceptcashpaymentCtrl)
          this.businessProfile.acceptcashondelivery = parseInt(acceptcashpaymentCtrl.value);
        break;
      case "areaofspeciality1":
        if (this.businessProfile.businessactivitytype == 1) {
          if (this.selectedCatrgories.length == 0)
            this.errorsList.push("Select one or more Category");
        } else if ([2, 3].includes(this.businessProfile.businessactivitytype!)) {
          if (this.selectedVehicletype == "")
            this.errorsList.push("Select Vehicle Type");

          if (this.brandSelectedItems.length == 0)
            this.errorsList.push("Select Brands");

          if (this.businessProfile.businessactivitytype == 3) {
            let conditionsCtrl = <NodeListOf<HTMLInputElement>>document.querySelectorAll('input[name="conditions"]:checked');
            if (conditionsCtrl.length == 0)
              this.errorsList.push("Select Conditions");
            else {
              let conds: any[] = [];
              conditionsCtrl.forEach(e => {
                //["Petrol", "Diesel", "Hybrid", "Electric"]
                switch (e.value) {
                  case "0":
                    conds.push(0);
                    break;
                  case "1":
                    conds.push(1);
                    break;
                }
              });

              if (conds.includes(0) && conds.includes(1))
                this.businessProfile.autocondition = 3;
              else if (conds.includes(0))
                this.businessProfile.autocondition = 1;
              else if (conds.includes(1))
                this.businessProfile.autocondition = 2;
            }
          }
        } else if (this.businessProfile.businessactivitytype == 4) {
          if (this.brandSelectedItems.length == 0)
            this.errorsList.push("Select Brands");
        }
        break;
      case "areaofspeciality2":
        if (this.businessProfile.businessactivitytype == 1) {
          let enginetypeCtrl = <NodeListOf<HTMLInputElement>>document.querySelectorAll('input[name="enginetype"]:checked');
          let deliverychannelCtrl = <NodeListOf<HTMLInputElement>>document.querySelectorAll('input[name="deliverychannel"]:checked');
          let acceptappointmentCtrl = <HTMLInputElement>document.querySelector('input[name="acceptappointment"]:checked');

          if (enginetypeCtrl.length == 0)
            this.errorsList.push("Select Engine Type");
          else {
            enginetypeCtrl.forEach(e => {
              //["Petrol", "Diesel", "Hybrid", "Electric"]
              switch (e.value) {
                case "0":
                  this.businessProfile.EnginePetrol = 1;
                  break;
                case "1":
                  this.businessProfile.EngineDiesel = 1;
                  break;
                case "2":
                  this.businessProfile.EngineHybrid = 1;
                  break;
                case "3":
                  this.businessProfile.EngineElectrical = 1;
                  break;
              }
            });
          }

          if (deliverychannelCtrl.length == 0)
            this.errorsList.push("Select Delivery Channel");
          else {
            let channels: any[] = [];
            deliverychannelCtrl.forEach(e => {
              //["Petrol", "Diesel", "Hybrid", "Electric"]
              switch (e.value) {
                case "0":
                  channels.push(0);
                  break;
                case "1":
                  channels.push(1);
                  break;
              }
            });

            if (channels.includes(0) && channels.includes(1))
              this.businessProfile.businessdeliverychannel = 3;
            else if (channels.includes(0))
              this.businessProfile.businessdeliverychannel = 1;
            else if (channels.includes(1))
              this.businessProfile.businessdeliverychannel = 2;
          }

          if (!acceptappointmentCtrl)
            this.errorsList.push("Select Accept Appointment");
          else
            this.businessProfile.appointment = parseInt(acceptappointmentCtrl.value);
        } else if (this.businessProfile.businessactivitytype == 2) {
          let enginetypeCtrl = <NodeListOf<HTMLInputElement>>document.querySelectorAll('input[name="enginetype"]:checked');
          let deliverychannelCtrl = <NodeListOf<HTMLInputElement>>document.querySelectorAll('input[name="deliverychannel"]:checked');
          let newConsCtrl = <NodeListOf<HTMLInputElement>>document.querySelectorAll('input[name="newCons"]:checked');
          let useConsCtrl = <NodeListOf<HTMLInputElement>>document.querySelectorAll('input[name="useCons"]:checked');
          let deliverychargeCtrl = <HTMLInputElement>document.getElementById("txt_deliverycharge");

          if (enginetypeCtrl.length == 0)
            this.errorsList.push("Select Engine Type");
          else {
            enginetypeCtrl.forEach(e => {
              //["Petrol", "Diesel", "Hybrid", "Electric"]
              switch (e.value) {
                case "0":
                  this.businessProfile.EnginePetrol = 1;
                  break;
                case "1":
                  this.businessProfile.EngineDiesel = 1;
                  break;
                case "2":
                  this.businessProfile.EngineHybrid = 1;
                  break;
                case "3":
                  this.businessProfile.EngineElectrical = 1;
                  break;
              }
            });
          }

          if (deliverychannelCtrl.length == 0)
            this.errorsList.push("Select Delivery Channel");
          else {
            let channels: any[] = [];
            deliverychannelCtrl.forEach(e => {
              switch (e.value) {
                case "0":
                  channels.push(0);
                  break;
                case "1":
                  channels.push(1);
                  break;
              }
            });

            if (channels.includes(0) && channels.includes(1))
              this.businessProfile.businessdeliverychannel = 3;
            else if (channels.includes(0))
              this.businessProfile.businessdeliverychannel = 1;
            else if (channels.includes(1))
              this.businessProfile.businessdeliverychannel = 2;
          }

          if (newConsCtrl.length == 0 && useConsCtrl.length == 0) {
            this.errorsList.push("Select Conditions");
          } else {
            let newCons: number[] = [];
            let useCons: number[] = [];
            if (newConsCtrl.length > 0) {
              newConsCtrl.forEach(e => {
                newCons.push(parseInt(e.value));
              });
            }

            if (useConsCtrl.length > 0) {
              useConsCtrl.forEach(e => {
                useCons.push(parseInt(e.value));
              });
            }

            if (newCons.length > 0) {
              if (newCons.includes(0)) {
                this.businessProfile.conditionnewgenuine = 1;
              } else {
                this.businessProfile.conditionnewgenuine = 0;
              }
              if (newCons.includes(1)) {
                this.businessProfile.conditionnewoem = 1;
              } else {
                this.businessProfile.conditionnewoem = 0;
              }
              if (newCons.includes(2)) {
                this.businessProfile.conditionnewaftermarket = 1;
              } else {
                this.businessProfile.conditionnewaftermarket = 0;
              }
            }

            if (useCons.length > 0) {
              if (useCons.includes(0)) {
                this.businessProfile.conditionusedgenuine = 1;
              } else {
                this.businessProfile.conditionusedgenuine = 0;
              }
              if (useCons.includes(1)) {
                this.businessProfile.conditionusedoem = 1;
              } else {
                this.businessProfile.conditionusedoem = 0;
              }
            }

            if (newCons.length > 0 && useCons.length > 0) {
              this.businessProfile.autocondition = 3;
            } else if (newCons.length > 0) {
              this.businessProfile.autocondition = 1;
            } else if (useCons.length > 0) {
              this.businessProfile.autocondition = 2;
            }
          }

          if (deliverychargeCtrl.value.trim() == "")
            this.errorsList.push("Enter Delivery Charge");
          else
            this.businessProfile.deliverycharges = parseFloat(deliverychargeCtrl.value);
        } else if (this.businessProfile.businessactivitytype == 4) {
          let conditionsCtrl = <NodeListOf<HTMLInputElement>>document.querySelectorAll('input[name="conditions"]:checked');
          let deliverychannelCtrl = <NodeListOf<HTMLInputElement>>document.querySelectorAll('input[name="deliverychannel"]:checked');
          let deliverychargeCtrl = <HTMLInputElement>document.getElementById("txt_deliverycharge");

          if (conditionsCtrl.length == 0)
            this.errorsList.push("Select Conditions");
          else {
            let conds: any[] = [];
            conditionsCtrl.forEach(e => {
              //["Petrol", "Diesel", "Hybrid", "Electric"]
              switch (e.value) {
                case "0":
                  conds.push(0);
                  break;
                case "1":
                  conds.push(1);
                  break;
              }
            });

            if (conds.includes(0) && conds.includes(1))
              this.businessProfile.autocondition = 3;
            else if (conds.includes(0))
              this.businessProfile.autocondition = 1;
            else if (conds.includes(1))
              this.businessProfile.autocondition = 2;
          }

          if (deliverychannelCtrl.length == 0)
            this.errorsList.push("Select Delivery Channel");
          else {
            let channels: any[] = [];
            deliverychannelCtrl.forEach(e => {
              switch (e.value) {
                case "0":
                  channels.push(0);
                  break;
                case "1":
                  channels.push(1);
                  break;
              }
            });

            if (channels.includes(0) && channels.includes(1))
              this.businessProfile.businessdeliverychannel = 3;
            else if (channels.includes(0))
              this.businessProfile.businessdeliverychannel = 1;
            else if (channels.includes(1))
              this.businessProfile.businessdeliverychannel = 2;
          }

          if (deliverychargeCtrl.value.trim() == "")
            this.errorsList.push("Enter Delivery Charge");
          else
            this.businessProfile.deliverycharges = parseFloat(deliverychargeCtrl.value);
        }
        break;
      case "areaofspeciality3":
        if (this.businessProfile.businessactivitytype == 1) {
          if (this.selectedVehicletype == "")
            this.errorsList.push("Select Vehicle Type");

          if (this.brandSelectedItems.length == 0)
            this.errorsList.push("Select Brands");
        } else if ([2, 4].includes(this.businessProfile.businessactivitytype!)) {
          if (this.selectedCatrgories.length == 0)
            this.errorsList.push("Select one or more Category");
        }
        break;
    }

    this.isValid = this.errorsList.length == 0;

    if (this.isValid) {
      document.getElementById("tap_" + formname)?.setAttribute("completed", "true");
    } else {
      document.getElementById("tap_" + formname)?.removeAttribute("completed");
    }

    if (!this.isValid) {
      (function ($) {
        $("html, body").animate({ scrollTop: 0 }, 500);
        return false;
      })(jQuery);
    }

    return this.isValid;
  }

  validateAll() {
    this.errorsList = [];

    // let specCtrl = <HTMLInputElement>document.querySelector('input[name="businessactivity"]:checked');
    // if (!specCtrl)
    //   this.errorsList.push("Select Business Activity Type");
    // else
    //   this.businessProfile.businessactivitytype = parseInt(specCtrl.value) + 1


    // let locationsCtrl = <HTMLSelectElement>document.getElementById("ddl_locations");
    // let tradeNumberCtrl = <HTMLInputElement>document.getElementById("txt_tradeNumber");
    // if (locationsCtrl.value.trim() == "")
    //   this.errorsList.push("Select Location");

    // if (tradeNumberCtrl.value.trim() == "")
    //   this.errorsList.push("Enter Trade Number");

    // if (locationsCtrl.value.trim() != "" && tradeNumberCtrl.value.trim() != "") {
    //   let exist = await this.getExsitBusiness();

    //   if (exist)
    //     this.errorsList.push("Business is already registered");
    //   else {
    //     this.businessProfile.tradelicensecity = locationsCtrl.value;
    //     this.businessProfile.tradelicensenumber = tradeNumberCtrl.value.trim();
    //   }
    // }

    let businessnameCtrl = <HTMLInputElement>document.getElementById("txt_businessname");
    let businessdescriptionCtrl = <HTMLInputElement>document.getElementById("txt_businessdescription");
    let businessnamearCtrl = <HTMLInputElement>document.getElementById("txt_businessnamear");
    let businessdescriptionarCtrl = <HTMLInputElement>document.getElementById("txt_businessdescriptionar");

    if (businessnameCtrl.value.trim() == "")
      this.errorsList.push("Enter Business Name");
    else
      this.businessProfile.businessname = businessnameCtrl.value;

    if (businessdescriptionCtrl.value.trim() == "")
      this.errorsList.push("Enter Business Description");
    else
      this.businessProfile.businessdescription = businessdescriptionCtrl.value;

    if (businessnamearCtrl.value.trim() == "")
      this.errorsList.push("Enter Business Name (Arabic)");
    else
      this.businessProfile.businessnamear = businessnamearCtrl.value;

    if (businessdescriptionarCtrl.value.trim() == "")
      this.errorsList.push("Enter Business Description (Arabic)");
    else
      this.businessProfile.businessdescriptionar = businessdescriptionarCtrl.value;

    let emailCtrl = <HTMLInputElement>document.getElementById("txt_email");
    let mobileCtrl = <HTMLInputElement>document.getElementById("txt_mobile");
    let workCtrl = <HTMLInputElement>document.getElementById("txt_work");
    let smobileCtrl = <HTMLInputElement>document.getElementById("txt_smobile");
    let whatsappCtrl = <HTMLInputElement>document.getElementById("txt_whatsapp");

    if (emailCtrl.value.trim() == "")
      this.errorsList.push("Enter Business Email");
    else if (!this.validateEmail(emailCtrl.value.trim()))
      this.errorsList.push("Enter Correct Business Email");
    else
      this.businessProfile.businessemail = emailCtrl.value;

    if (mobileCtrl.value.trim() == "")
      this.errorsList.push("Enter Business Mobile Number");
    else {
      let mobile = "";

      if (mobileCtrl.value.length == 10 && mobileCtrl.value[0] == "0")
        mobile = mobileCtrl.value.substring(1);
      else
        mobile = mobileCtrl.value;

      if (mobile.length != 9)
        this.errorsList.push("Enter Correct Business Mobile Number");
      else
        this.businessProfile.businessmobilenumber = mobile;
    }

    if (workCtrl.value.trim() == "")
      this.errorsList.push("Enter Business Work Numbe");
    else {
      let phone = "";

      if (workCtrl.value.length == 10 && workCtrl.value[0] == "0")
        phone = workCtrl.value.substring(1);
      else
        phone = workCtrl.value;

      if (phone.length != 9)
        this.errorsList.push("Enter Correct Business Work Number");
      else
        this.businessProfile.businesscontactnumber = workCtrl.value;
    }

    if (smobileCtrl.value.trim() != "") {
      let phone = "";

      if (smobileCtrl.value.length == 10 && smobileCtrl.value[0] == "0")
        phone = smobileCtrl.value.substring(1);
      else
        phone = smobileCtrl.value;

      if (phone.length != 9)
        this.errorsList.push("Enter Correct Business Secondary Mobile Number");
      else
        this.businessProfile.businesssecondarymobilenumber = smobileCtrl.value;
    }

    if (whatsappCtrl.value.trim() != "") {
      let phone = "";

      if (whatsappCtrl.value.length == 10 && whatsappCtrl.value[0] == "0")
        phone = whatsappCtrl.value.substring(1);
      else
        phone = whatsappCtrl.value;

      if (phone.length != 9)
        this.errorsList.push("Enter Correct Business Whatsapp Number");
      else
        this.businessProfile.businesswhatsappnumber = whatsappCtrl.value;
    }

    let addrCtrl = <HTMLInputElement>document.getElementById("txt_address");
    if (!this.currentLocation)
      this.errorsList.push("Select Location");
    else {
      this.businessProfile.businesslat = this.latitude;
      this.businessProfile.businesslng = this.longitude;
      this.businessProfile.businesscity = this.city;
      this.businessProfile.businesscountry = this.country;
    }
    if (addrCtrl.value.trim() == "")
      this.errorsList.push("Enter Address Details");
    else
      this.businessProfile.businessaddress = addrCtrl.value;

    let trademarkownerCtrl = <HTMLInputElement>document.querySelector('input[name="trademarkowner"]:checked');
    if (!this.validatSelectedHours())
      this.errorsList.push("Select Correct working hours");

    if (!trademarkownerCtrl)
      this.errorsList.push("Select Are you Trademark Owner/Attorney/Agency");
    else
      this.businessProfile.businesstrademark = parseInt(trademarkownerCtrl.value);

    let websiteCtrl = <HTMLInputElement>document.getElementById("txt_website");

    // if (this.businessLogoFile == null)
    //   this.errorsList.push("Select Business/Company Logo");

    // if (!this.coverFile)
    //   this.errorsList.push("Add Cover Photo");
    // if (this.otherFiles.length == 0)
    //   this.errorsList.push("Select Business Images");

    // if (this.businessImageFiles.length == 0)
    //   this.errorsList.push("Select Business Images");

    // if ((this.coverUpdate || this.otherImagesUpdated) && (!this.coverFile || this.otherFiles.length == 0))
    if ((this.otherImagesUpdated) && (!this.coverFile || this.otherFiles.length == 0))
      this.errorsList.push("Select Cover Photo and Business Images");

    if (websiteCtrl.value != "")
      this.businessProfile.businesswebsite = websiteCtrl.value;
    else
      this.businessProfile.businesswebsite = null;

    let ibannumberCtrl = <HTMLInputElement>document.getElementById("txt_ibannumber");
    let banknameCtrl = <HTMLInputElement>document.getElementById("txt_bankname");
    let beneficiarynameCtrl = <HTMLInputElement>document.getElementById("txt_beneficiaryname");

    if (ibannumberCtrl.value != "")
      this.businessProfile.businessibannumber = ibannumberCtrl.value;
    else
      this.businessProfile.businessibannumber = null;

    if (banknameCtrl.value != "")
      this.businessProfile.businessbankname = banknameCtrl.value;
    else
      this.businessProfile.businessbankname = null;

    if (beneficiarynameCtrl.value != "")
      this.businessProfile.businessbankbeneficiaryname = beneficiarynameCtrl.value;
    else
      this.businessProfile.businessbankbeneficiaryname = null;

    let termsconditionsCtrl = <HTMLInputElement>document.getElementById("txt_termsconditions");
    let acceptcashpaymentCtrl = <HTMLInputElement>document.querySelector('input[name="acceptcashpayment"]:checked');

    if (termsconditionsCtrl.value.trim() != "")
      this.businessProfile.termsandconditions = termsconditionsCtrl.value.trim();

    if (acceptcashpaymentCtrl)
      this.businessProfile.acceptcashondelivery = parseInt(acceptcashpaymentCtrl.value);

    if (this.businessProfile.businessactivitytype == 1) {
      if (this.selectedCatrgories.length == 0)
        this.errorsList.push("Select one or more Category");
    } else if ([2, 3].includes(this.businessProfile.businessactivitytype!)) {
      if (this.selectedVehicletype == "")
        this.errorsList.push("Select Vehicle Type");

      if (this.brandSelectedItems.length == 0)
        this.errorsList.push("Select Brands");

      if (this.businessProfile.businessactivitytype == 3) {
        let conditionsCtrl = <NodeListOf<HTMLInputElement>>document.querySelectorAll('input[name="conditions"]:checked');
        if (conditionsCtrl.length == 0)
          this.errorsList.push("Select Conditions");
        else {
          let conds: any[] = [];
          conditionsCtrl.forEach(e => {
            //["Petrol", "Diesel", "Hybrid", "Electric"]
            switch (e.value) {
              case "0":
                conds.push(0);
                break;
              case "1":
                conds.push(1);
                break;
            }
          });

          if (conds.includes(0) && conds.includes(1))
            this.businessProfile.autocondition = 3;
          else if (conds.includes(0))
            this.businessProfile.autocondition = 1;
          else if (conds.includes(1))
            this.businessProfile.autocondition = 2;
        }
      }
    } else if (this.businessProfile.businessactivitytype == 4) {
      if (this.brandSelectedItems.length == 0)
        this.errorsList.push("Select Brands");
    }

    if (this.businessProfile.businessactivitytype == 1) {
      let enginetypeCtrl = <NodeListOf<HTMLInputElement>>document.querySelectorAll('input[name="enginetype"]:checked');
      let deliverychannelCtrl = <NodeListOf<HTMLInputElement>>document.querySelectorAll('input[name="deliverychannel"]:checked');
      let acceptappointmentCtrl = <HTMLInputElement>document.querySelector('input[name="acceptappointment"]:checked');

      if (enginetypeCtrl.length == 0)
        this.errorsList.push("Select Engine Type");
      else {
        enginetypeCtrl.forEach(e => {
          //["Petrol", "Diesel", "Hybrid", "Electric"]
          switch (e.value) {
            case "0":
              this.businessProfile.EnginePetrol = 1;
              break;
            case "1":
              this.businessProfile.EngineDiesel = 1;
              break;
            case "2":
              this.businessProfile.EngineHybrid = 1;
              break;
            case "3":
              this.businessProfile.EngineElectrical = 1;
              break;
          }
        });
      }

      if (deliverychannelCtrl.length == 0)
        this.errorsList.push("Select Delivery Channel");
      else {
        let channels: any[] = [];
        deliverychannelCtrl.forEach(e => {
          //["Petrol", "Diesel", "Hybrid", "Electric"]
          switch (e.value) {
            case "0":
              channels.push(0);
              break;
            case "1":
              channels.push(1);
              break;
          }
        });

        if (channels.includes(0) && channels.includes(1))
          this.businessProfile.businessdeliverychannel = 3;
        else if (channels.includes(0))
          this.businessProfile.businessdeliverychannel = 1;
        else if (channels.includes(1))
          this.businessProfile.businessdeliverychannel = 2;
      }

      if (!acceptappointmentCtrl)
        this.errorsList.push("Select Accept Appointment");
      else
        this.businessProfile.appointment = parseInt(acceptappointmentCtrl.value);
    } else if (this.businessProfile.businessactivitytype == 2) {
      let enginetypeCtrl = <NodeListOf<HTMLInputElement>>document.querySelectorAll('input[name="enginetype"]:checked');
      let deliverychannelCtrl = <NodeListOf<HTMLInputElement>>document.querySelectorAll('input[name="deliverychannel"]:checked');
      let newConsCtrl = <NodeListOf<HTMLInputElement>>document.querySelectorAll('input[name="newCons"]:checked');
      let useConsCtrl = <NodeListOf<HTMLInputElement>>document.querySelectorAll('input[name="useCons"]:checked');
      let deliverychargeCtrl = <HTMLInputElement>document.getElementById("txt_deliverycharge");

      if (enginetypeCtrl.length == 0)
        this.errorsList.push("Select Engine Type");
      else {
        enginetypeCtrl.forEach(e => {
          //["Petrol", "Diesel", "Hybrid", "Electric"]
          switch (e.value) {
            case "0":
              this.businessProfile.EnginePetrol = 1;
              break;
            case "1":
              this.businessProfile.EngineDiesel = 1;
              break;
            case "2":
              this.businessProfile.EngineHybrid = 1;
              break;
            case "3":
              this.businessProfile.EngineElectrical = 1;
              break;
          }
        });
      }

      if (deliverychannelCtrl.length == 0)
        this.errorsList.push("Select Delivery Channel");
      else {
        let channels: any[] = [];
        deliverychannelCtrl.forEach(e => {
          switch (e.value) {
            case "0":
              channels.push(0);
              break;
            case "1":
              channels.push(1);
              break;
          }
        });

        if (channels.includes(0) && channels.includes(1))
          this.businessProfile.businessdeliverychannel = 3;
        else if (channels.includes(0))
          this.businessProfile.businessdeliverychannel = 1;
        else if (channels.includes(1))
          this.businessProfile.businessdeliverychannel = 2;
      }

      if (newConsCtrl.length == 0 && useConsCtrl.length == 0) {
        this.errorsList.push("Select Conditions");
      } else {
        let newCons: number[] = [];
        let useCons: number[] = [];
        if (newConsCtrl.length > 0) {
          newConsCtrl.forEach(e => {
            newCons.push(parseInt(e.value));
          });
        }

        if (useConsCtrl.length > 0) {
          useConsCtrl.forEach(e => {
            useCons.push(parseInt(e.value));
          });
        }

        if (newCons.length > 0) {
          if (newCons.includes(0)) {
            this.businessProfile.conditionnewgenuine = 1;
          } else {
            this.businessProfile.conditionnewgenuine = 0;
          }
          if (newCons.includes(1)) {
            this.businessProfile.conditionnewoem = 1;
          } else {
            this.businessProfile.conditionnewoem = 0;
          }
          if (newCons.includes(2)) {
            this.businessProfile.conditionnewaftermarket = 1;
          } else {
            this.businessProfile.conditionnewaftermarket = 0;
          }
        }

        if (useCons.length > 0) {
          if (useCons.includes(0)) {
            this.businessProfile.conditionusedgenuine = 1;
          } else {
            this.businessProfile.conditionusedgenuine = 0;
          }
          if (useCons.includes(1)) {
            this.businessProfile.conditionusedoem = 1;
          } else {
            this.businessProfile.conditionusedoem = 0;
          }
        }

        if (newCons.length > 0 && useCons.length > 0) {
          this.businessProfile.autocondition = 3;
        } else if (newCons.length > 0) {
          this.businessProfile.autocondition = 1;
        } else if (useCons.length > 0) {
          this.businessProfile.autocondition = 2;
        }
      }

      if (deliverychargeCtrl.value.trim() == "")
        this.errorsList.push("Enter Delivery Charge");
      else
        this.businessProfile.deliverycharges = parseFloat(deliverychargeCtrl.value);
    } else if (this.businessProfile.businessactivitytype == 4) {
      let conditionsCtrl = <NodeListOf<HTMLInputElement>>document.querySelectorAll('input[name="conditions"]:checked');
      let deliverychannelCtrl = <NodeListOf<HTMLInputElement>>document.querySelectorAll('input[name="deliverychannel"]:checked');
      let deliverychargeCtrl = <HTMLInputElement>document.getElementById("txt_deliverycharge");

      if (conditionsCtrl.length == 0)
        this.errorsList.push("Select Conditions");
      else {
        let conds: any[] = [];
        conditionsCtrl.forEach(e => {
          //["Petrol", "Diesel", "Hybrid", "Electric"]
          switch (e.value) {
            case "0":
              conds.push(0);
              break;
            case "1":
              conds.push(1);
              break;
          }
        });

        if (conds.includes(0) && conds.includes(1))
          this.businessProfile.autocondition = 3;
        else if (conds.includes(0))
          this.businessProfile.autocondition = 1;
        else if (conds.includes(1))
          this.businessProfile.autocondition = 2;
      }

      if (deliverychannelCtrl.length == 0)
        this.errorsList.push("Select Delivery Channel");
      else {
        let channels: any[] = [];
        deliverychannelCtrl.forEach(e => {
          switch (e.value) {
            case "0":
              channels.push(0);
              break;
            case "1":
              channels.push(1);
              break;
          }
        });

        if (channels.includes(0) && channels.includes(1))
          this.businessProfile.businessdeliverychannel = 3;
        else if (channels.includes(0))
          this.businessProfile.businessdeliverychannel = 1;
        else if (channels.includes(1))
          this.businessProfile.businessdeliverychannel = 2;
      }

      if (deliverychargeCtrl.value.trim() == "")
        this.errorsList.push("Enter Delivery Charge");
      else
        this.businessProfile.deliverycharges = parseFloat(deliverychargeCtrl.value);
    }

    if (this.businessProfile.businessactivitytype == 1) {
      if (this.selectedVehicletype == "")
        this.errorsList.push("Select Vehicle Type");

      if (this.brandSelectedItems.length == 0)
        this.errorsList.push("Select Brands");
    } else if ([2, 4].includes(this.businessProfile.businessactivitytype!)) {
      if (this.selectedCatrgories.length == 0)
        this.errorsList.push("Select one or more Category");
    }


    this.isValid = this.errorsList.length == 0;


    if (!this.isValid) {
      (function ($) {
        $("html, body").animate({ scrollTop: 0 }, 500);
        return false;
      })(jQuery);
    }

    return this.isValid;
  }

  tapChange(evt: any, currentTap: any, perviouseTap: any) {
    let tap = perviouseTap ? perviouseTap : currentTap;
    let isCompleted = document.getElementById("tap_" + tap)?.getAttribute("completed") == "true";

    if (this.isValid && isCompleted) {
      // Declare all variables
      var i, tabcontent, tablinks;

      // Get all elements with class="tabcontent" and hide them
      tabcontent = document.getElementsByClassName("tabcontent") as HTMLCollectionOf<HTMLDivElement>;
      for (i = 0; i < tabcontent.length; i++) {
        tabcontent[i].style.display = "none";
      }

      // Get all elements with class="tablinks" and remove the class "active"
      tablinks = document.getElementsByClassName("tablinks");
      for (i = 0; i < tablinks.length; i++) {
        tablinks[i].className = tablinks[i].className.replace(" active", "");
      }

      // Show the current tab, and add an "active" class to the link that opened the tab
      document.getElementById(currentTap)!.style.display = "block";
      if (currentTap)
        document.getElementById('tap_' + currentTap)!.className += " active";
      // evt.currentTarget.className += " active";

      (function ($) {
        $("html, body").animate({ scrollTop: 0 }, 500);
        return false;
      })(jQuery);
    }

    return false;
  }

  isCompleted(tap: any) {
    let isCompleted = document.getElementById("tap_" + tap)?.getAttribute("completed") == "true";

    return isCompleted;
  }

  getSelectFromTime(time?: string) {
    if (time && time != null) {
      var h = time.split(":")[0];
      var m = time.split(":")[1];

      if (parseInt(h) > 12) {
        var nh = parseInt(h) - 12;

        var sh = nh > 9 ? nh.toString() : "0" + nh.toString();
        return sh + ":" + m + " PM";
      }
      if (parseInt(h) == 12) {
        return "12:" + m + " PM";
      } else if (parseInt(h) == 0) {
        return "12:" + m + " AM";
      } else
        return h + ":" + m + " AM";
    }

    return null;
  }

  chenge24Hours() {
    this.is24Hours = !this.is24Hours;

    if (this.canSetSameTime) {
      (<HTMLInputElement>document.getElementById("applytoall")).value = "0";
      (<HTMLInputElement>document.getElementById("applytoall")).checked = false;

      this.canSetSameTime = false;
    }

    if (!this.is24Hours) {
      this.busWorkHours[0] = null;
      this.busWorkHours[1] = null;
      this.busWorkHours[2] = null;
      this.busWorkHours[3] = null;
      this.busWorkHours[4] = null;
      this.busWorkHours[5] = null;
      this.busWorkHours[6] = null;

      (function ($) {
        $('.select').each((e: any, i: any) => {
          $(e).val("");
        });
      })(jQuery);

      this.dynamicScriptLoader.load("main");

      (function ($) {
        $(document).on('change', '.select', function (e: any) {
          e.target.dispatchEvent(new Event('change'));
        });

        $('.select').niceSelect('update');
      })(jQuery);
    } else {


      this.busWorkHours[0] = {
        dayofweek: 1,
        starttime: "12:00 AM",
        closetime: "12:00 AM",
        breakstarttime: null,
        breakendtime: null,
        isweekend: 0
      };
      this.busWorkHours[1] = {
        dayofweek: 2,
        starttime: "12:00 AM",
        closetime: "12:00 AM",
        breakstarttime: null,
        breakendtime: null,
        isweekend: 0
      };
      this.busWorkHours[2] = {
        dayofweek: 3,
        starttime: "12:00 AM",
        closetime: "12:00 AM",
        breakstarttime: null,
        breakendtime: null,
        isweekend: 0
      };
      this.busWorkHours[3] = {
        dayofweek: 4,
        starttime: "12:00 AM",
        closetime: "12:00 AM",
        breakstarttime: null,
        breakendtime: null,
        isweekend: 0
      };
      this.busWorkHours[4] = {
        dayofweek: 5,
        starttime: "12:00 AM",
        closetime: "12:00 AM",
        breakstarttime: null,
        breakendtime: null,
        isweekend: 0
      };
      this.busWorkHours[5] = {
        dayofweek: 6,
        starttime: "12:00 AM",
        closetime: "12:00 AM",
        breakstarttime: null,
        breakendtime: null,
        isweekend: 0
      };
      this.busWorkHours[6] = {
        dayofweek: 7,
        starttime: "12:00 AM",
        closetime: "12:00 AM",
        breakstarttime: null,
        breakendtime: null,
        isweekend: 0
      };
    }
  }

  changeWorkHours(event: Event, index: number) {
    let ddl = event.target as HTMLSelectElement;
    let isWorkTime = ddl.id.startsWith("ddl_from") || ddl.id.startsWith("ddl_to");
    let isStart = ddl.id.startsWith("ddl_from") || ddl.id.startsWith("ddl_bfrom");

    let selected = ddl.options[ddl.selectedIndex];

    if (this.busWorkHours[index] == null) {
      this.busWorkHours[index] = {
        dayofweek: index + 1,
        starttime: null,
        closetime: null,
        breakstarttime: null,
        breakendtime: null,
        isweekend: null
      };
    }

    if (isWorkTime) {
      if (isStart)
        this.busWorkHours[index]!.starttime = selected.text != "" ? selected.text : null;
      else
        this.busWorkHours[index]!.closetime = selected.text != "" ? selected.text : null;
    } else {
      if (isStart)
        this.busWorkHours[index]!.breakstarttime = selected.text != "" ? selected.text : null;
      else
        this.busWorkHours[index]!.breakendtime = selected.text != "" ? selected.text : null;
    }

    this.canSetSameTime = this.busWorkHours.filter(wh => wh != null && wh!.starttime != null && wh!.closetime != null).length > 0;

    if (this.canSetSameTime)
      this.busWorkHours[index]!.isweekend = 0;
  }

  setSameToAll(event: Event) {
    let chck = <HTMLInputElement>event.target
    if (chck.checked) {
      const workHours: BusWorkHours | null | undefined = this.busWorkHours.find(wh => wh != null && wh!.starttime != null && wh!.closetime != null);

      if (workHours && workHours != null) {
        this.busWorkHours[0] = JSON.parse(JSON.stringify(workHours)) as BusWorkHours;
        this.busWorkHours[0].dayofweek = 1;

        this.busWorkHours[1] = JSON.parse(JSON.stringify(workHours)) as BusWorkHours;
        this.busWorkHours[1].dayofweek = 2;

        this.busWorkHours[2] = JSON.parse(JSON.stringify(workHours)) as BusWorkHours;
        this.busWorkHours[2].dayofweek = 3;

        this.busWorkHours[3] = JSON.parse(JSON.stringify(workHours)) as BusWorkHours;
        this.busWorkHours[3].dayofweek = 4;

        this.busWorkHours[4] = JSON.parse(JSON.stringify(workHours)) as BusWorkHours;
        this.busWorkHours[4].dayofweek = 5;

        this.busWorkHours[5] = JSON.parse(JSON.stringify(workHours)) as BusWorkHours;
        this.busWorkHours[5].dayofweek = 6;

        this.busWorkHours[6] = JSON.parse(JSON.stringify(workHours)) as BusWorkHours;
        this.busWorkHours[6].dayofweek = 7;

        let _self = this;

        (function ($) {
          for (let index = 0; index < 7; index++) {
            const ddlFrom = $("#ddl_from_" + index);
            const ddlTo = $("#ddl_to_" + index);
            const ddlBFrom = $("#ddl_bfrom_" + index);
            const ddlBTo = $("#ddl_bto_" + index);

            let startValue = _self.hours.find(h => h.text == workHours?.starttime);
            let endValue = _self.hours.find(h => h.text == workHours?.closetime);
            let startBValue = _self.hours.find(h => h.text == workHours?.breakstarttime);
            let endBValue = _self.hours.find(h => h.text == workHours?.breakendtime);

            ddlFrom.val(startValue.value);
            ddlTo.val(endValue.value);
            if (startBValue)
              ddlBFrom.val(startBValue.value);
            if (endBValue)
              ddlBTo.val(endBValue.value);

            $('.select').niceSelect('update');
          }
        })(jQuery);
      }
    }
  }

  isWeekEnd(index: number) {
    return this.busWorkHours[index] != null && this.busWorkHours[index]!.isweekend == 1;
  }

  markAsWeekend(index: number) {
    if (this.busWorkHours[index] == null) {
      this.busWorkHours[index] = {
        dayofweek: index + 1,
        starttime: null,
        closetime: null,
        breakstarttime: null,
        breakendtime: null,
        isweekend: 1
      };
    } else {
      if (this.busWorkHours[index]!.isweekend == null || this.busWorkHours[index]!.isweekend == 0) {
        this.busWorkHours[index] = {
          dayofweek: index + 1,
          starttime: null,
          closetime: null,
          breakstarttime: null,
          breakendtime: null,
          isweekend: 1
        };
      } else {
        this.busWorkHours[index] = {
          dayofweek: index + 1,
          starttime: null,
          closetime: null,
          breakstarttime: null,
          breakendtime: null,
          isweekend: 0
        };

        this.dynamicScriptLoader.load("main");
      }
    }

    (function ($) {
      setTimeout(() => {
        const ddlFrom = $("#ddl_from_" + index);
        const ddlTo = $("#ddl_to_" + index);
        const ddlBFrom = $("#ddl_bfrom_" + index);
        const ddlBTo = $("#ddl_bto_" + index);

        ddlFrom.val("");
        ddlTo.val("");
        ddlBFrom.val("");
        ddlBTo.val("");

        $('.select').niceSelect('update');
      }, 50);
    })(jQuery);
  }

  validatSelectedHours() {
    if (this.is24Hours) return true;

    var nullValues = this.busWorkHours.filter((h) => h == null);

    if (nullValues.length > 0)
      return false;
    else {
      for (let item of this.busWorkHours) {
        if ((item!.starttime == null || item!.closetime == null) &&
          item!.isweekend == 0)
          return false;
        else {
          if (item!.starttime != null && item!.closetime != null) {
            var selectedStart = this.hours.find(
              (wh) => wh!["text"] == item!.starttime);

            var selectedClose = this.hours.find(
              (wh) => wh!["text"] == item!.closetime);

            var selectedStartBreak = item!.breakstarttime != null
              ? this.hours.find((wh) => wh!["text"] == item!.breakstarttime)
              : null;

            var selectedEndBreak = item!.breakendtime != null
              ? this.hours.find((wh) => wh!["text"] == item!.breakendtime)
              : null;

            if (parseInt(selectedStart!["value"].toString()) >
              parseInt(selectedClose!["value"].toString())) {
              return false;
            } else if (selectedStartBreak != null &&
              selectedEndBreak != null &&
              parseInt(selectedStartBreak["value"].toString()) >
              parseInt(selectedEndBreak["value"].toString())) {
              return false;
            } else if (selectedStartBreak != null || selectedEndBreak != null) {
              if ((selectedStartBreak != null && selectedEndBreak == null) ||
                (selectedStartBreak == null && selectedEndBreak != null)) {
                return false;
              }
            } else if (selectedStartBreak != null &&
              selectedEndBreak != null &&
              (parseInt(selectedStartBreak["value"].toString()) <
                parseInt(selectedStart["value"].toString()) ||
                parseInt(selectedStartBreak["value"].toString()) >
                parseInt(selectedClose["value"].toString()))) {
              return false;
            } else if (selectedStartBreak != null &&
              selectedEndBreak != null &&
              (parseInt(selectedEndBreak["value"].toString()) <
                parseInt(selectedStart["value"].toString()) ||
                parseInt(selectedEndBreak["value"].toString()) >
                parseInt(selectedClose["value"].toString()))) {
              return false;
            }
          }
        }
      }
    }

    return true;
  }


  async getImageDimensions(file: File): Promise<{ width: number, height: number }> {
    return new Promise((resolve, reject) => {
      const img = new Image();

      img.onload = () => {
        const width = img.width;
        const height = img.height;

        resolve({ width, height });
      };

      img.onerror = () => {
        reject('Error loading image');
      };

      img.src = URL.createObjectURL(file);
    });
  }

  async onChange(event: any, prifix: string, isCover: boolean = false) {
    let files = event.target.files;

    this.errorsList = [];

    if (prifix == "_BusinessImage" && files.length > 3) {
      event.preventDefault();
      this.errorsList.push("Max Photo is 3");
      event.target.value = "";
      return;
    }

    let dimensionsErrors = 0;
    for (const f of event.target.files) {
      const dimensions = await this.getImageDimensions(f);
      if (dimensions.width < dimensions.height) {
        dimensionsErrors++;
      }
    }

    if (dimensionsErrors > 0) {
      event.preventDefault();
      this.errorsList.push("One or more Images are not in landscape orientation");
      event.target.value = "";
      return;
    }

    // https://github.com/vijayrchoudhary/compressImageAngular
    var orientation = -1;
    let comFiles: any[] = [];
    for (const f of event.target.files) {
      const fileSizeInMB = f.size / (1024 * 1024); // Convert bytes to megabytes
      const maxFileSizeInMB = 0.5;

      if (fileSizeInMB > maxFileSizeInMB) {
        const imageFile = await this.readAndCompressFile(f);
        comFiles.push(imageFile);
      } else {
        comFiles.push(f);
      }

      // const imageFile = await this.readAndCompressFile(f);
      // comFiles.push(imageFile);
    }

    files = comFiles;

    let exist = this.files.find(f => f.fileName.includes("_" + this.businessProfile.userid + prifix));

    if (files.length > 0) {
      if (prifix == "_BusinessImage") {
        if (isCover) {
          this.coverFile = files[0];
          this.coverUpdate = true;
        }
        else {
          this.otherFiles = files;
          this.otherImagesUpdated = true;
        }
      }
    }
    else {
      if (prifix == "_BusinessImage") {
        if (isCover) {
          this.coverFile = null;
          this.coverUpdate = false;
        }
        else {
          this.otherFiles = [];
          this.otherImagesUpdated = false;
        }

        if (this.coverFile == null && this.otherFiles.length == 0) {
          let index = this.files.indexOf(exist);
          this.files.splice(index, 1);
        }
      } else if (prifix == "_BusinessLogo") {
        this.businessLogoFile = null;
        if (exist) {
          let index = this.files.indexOf(exist);
          this.files.splice(index, 1);
        }
      } else if (prifix == "_BusinessLicense") {
        this.businessLicenseFile = null;
        this.licenseUpdated = false;
        if (exist) {
          let index = this.files.indexOf(exist);
          this.files.splice(index, 1);
        }
      }
    }

    if (prifix == "_BusinessImage") {
      let allFiles = this.coverFile ? [this.coverFile, ...this.otherFiles] : this.otherFiles;
      if (exist)
        exist.files = allFiles;
      else
        this.files!.push({
          "files": allFiles,
          "fileName": "_" + this.businessProfile.userid + (prifix).replaceAll(/undefined/g, "null")
        });
    } else if (prifix == "_BusinessLogo") {
      this.businessLogoFile = files[0];
      if (exist)
        exist.files = files;
      else
        this.files!.push({
          "files": files,
          "fileName": "_" + this.businessProfile.userid + (prifix).replaceAll(/undefined/g, "null")
        });
    } else if (prifix == "_BusinessLicense") {
      this.businessLicenseFile = files[0];
      this.licenseUpdated = true;
      if (exist)
        exist.files = files;
      else
        this.files!.push({
          "files": files,
          "fileName": "_" + this.businessProfile.userid + (prifix).replaceAll(/undefined/g, "null")
        });
    }

    let currentFiles = this.files.find(f => f.fileName.includes(prifix));

    switch (prifix) {
      case "_BusinessImage":
        for (let i = 1; i <= 4; i++) {
          (this.businessProfile as any)["businessimagepath" + i.toString()] = null;
        }

        if (currentFiles) {
          if (currentFiles.files.length == 1) {
            let file: File = currentFiles.files[0];
            var extIndex = file.name.lastIndexOf(".") + 1;

            var ext = file.name
              .substring(extIndex)
              .replaceAll(RegExp(/[^A-Za-z]/g), '');

            this.businessProfile["businessimagepath1"] = ("_" + this.businessProfile.userid + "_BusinessImage." + ext).replaceAll(/undefined/g, "null");
          } else {
            for (let i = 0; i < currentFiles.files.length; i++) {
              let file: File = currentFiles.files[i];
              var extIndex = file.name.lastIndexOf(".") + 1;

              var ext = file.name
                .substring(extIndex)
                .replaceAll(RegExp(/[^A-Za-z]/g), '');

              switch (i) {
                case 0:
                  this.businessProfile["businessimagepath1"] = ("_" + this.businessProfile.userid + "_BusinessImage_1." + ext).replaceAll(/undefined/g, "null");
                  break;
                case 1:
                  this.businessProfile["businessimagepath2"] = ("_" + this.businessProfile.userid + "_BusinessImage_2." + ext).replaceAll(/undefined/g, "null");
                  break;
                case 2:
                  this.businessProfile["businessimagepath3"] = ("_" + this.businessProfile.userid + "_BusinessImage_3." + ext).replaceAll(/undefined/g, "null");
                  break;
                case 3:
                  this.businessProfile["businessimagepath4"] = ("_" + this.businessProfile.userid + "_BusinessImage_4." + ext).replaceAll(/undefined/g, "null");
                  break;
              }
            }
          }
        }
        break;
      case "_BusinessLogo":
        if (currentFiles) {
          let file: File = currentFiles.files[0];
          var extIndex = file.name.lastIndexOf(".") + 1;

          var ext = file.name
            .substring(extIndex)
            .replaceAll(RegExp(/[^A-Za-z]/g), '');
          this.businessProfile.businesslogopath = ("_" + this.businessProfile.userid + "_BusinessLogo." + ext).replaceAll(/undefined/g, "null");
        } else
          this.businessProfile.businesslogopath = null;
        break;
      case "_BusinessLicense":
        if (currentFiles) {
          let file: File = currentFiles.files[0];
          var extIndex = file.name.lastIndexOf(".") + 1;

          var ext = file.name
            .substring(extIndex)
            .replaceAll(RegExp(/[^A-Za-z]/g), '');
          this.businessProfile.businesslicensepath = ("_" + this.businessProfile.userid + "_BusinessLicense." + ext).replaceAll(/undefined/g, "null");
        } else
          this.businessProfile.businesslicensepath = null;
        break;
    }
  }

  private readAndCompressFile(file: File): Promise<any> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = async (e) => {

        // let result = await this.imageCompress.compressFile(e!.target!.result as string, -1, 90, 90, 500, 300).then(
        //   r => {
        //     return r;
        //   }
        // );

        let result = await this.imageCompress.compressFile(e!.target!.result as string, -1).then(
          r => {
            return r;
          }
        );

        const imageName = file.name;

        const imageBlob = this.dataURItoBlob(result.split(',')[1]);
        const imageFile = new File([imageBlob], imageName, { type: 'image/jpeg' });
        resolve(imageFile);
      };
      reader.onerror = () => {
        reject(new Error('Unable to read..'));
      };
      reader.readAsDataURL(file);
    });
  }

  dataURItoBlob(dataURI: any) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/jpeg' });
    return blob;
  }


  async mapLoad() {
    if (!this.map)
      this.loader
        .importLibrary('maps')
        .then(async ({ Map }) => {
          this.map = new Map(document.getElementById("map") as HTMLElement, {
            center: { lat: this.latitude, lng: this.longitude },
            zoom: 16,
            mapId: "37c3af23858be656"
          });

          let latlng = {
            lat: this.latitude,
            lng: this.longitude
          };

          const { AdvancedMarkerElement } = await google.maps.importLibrary("marker") as google.maps.MarkerLibrary;

          // The marker, positioned at Uluru
          this.marker = new AdvancedMarkerElement({
            map: this.map,
            position: latlng,
            title: 'Uluru',
          });

          // Configure the click listener.
          this.map.addListener("click", (mapsMouseEvent: any) => {
            let position = mapsMouseEvent.latLng.toJSON();

            if (this.marker)
              this.marker.position = position;
            else
              this.marker = new AdvancedMarkerElement({
                map: this.map,
                position: position,
                title: 'Uluru',
              });

            this.getLocationInfo(position);
          });

          this.marker.addListener("click", () => {
            this.map!.setZoom(14);
            this.map!.setCenter(this.marker!.position as google.maps.LatLng);
          });

          this.getLocationInfo(latlng);

          let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
            // componentRestrictions: this.componentRestrictions
          });

          autocomplete.setComponentRestrictions({
            country: ["ae"]
          });

          autocomplete.addListener("place_changed", () => {
            this.ngZone.run(() => {
              //get the place result
              let place: google.maps.places.PlaceResult = autocomplete.getPlace();

              //verify result
              if (place.geometry === undefined || place.geometry === null) {
                return;
              }

              latlng = {
                lat: place.geometry!.location!.lat(),
                lng: place.geometry!.location!.lng()
              };

              if (this.marker)
                this.marker.position = latlng;
              else
                this.marker = new AdvancedMarkerElement({
                  map: this.map,
                  position: latlng,
                  title: 'Uluru',
                });

              this.map!.setZoom(14);
              this.map!.setCenter(this.marker!.position as google.maps.LatLng);

              this.getLocationInfo(latlng);
            });
          });
        })
        .catch((e) => {
          // do something
        });



  }

  getLocationInfo(latlng: any) {
    let _self = this;
    var geocoder = new google.maps.Geocoder();

    geocoder.geocode({
      'location': latlng
    }, function (results) {
      if (results && results[0]) {
        let addrCtrl = <HTMLInputElement>document.getElementById("txt_address");

        _self.latitude = results[0].geometry.location.lat();
        _self.longitude = results[0].geometry.location.lng();
        _self.currentLocation = results[0].formatted_address;
        addrCtrl.value = _self.currentLocation;
        let splitBy = _self.currentLocation.includes('-') ? '-' : ','
        let addr = _self.currentLocation.split(splitBy);
        _self.city = addr[addr.length - 2].trim();
        _self.country = addr[addr.length - 1].trim();
        // console.log(results);
      } else {
        console.log('Not found');
      }
    });
  }

  // get() {
  //   this.apiloader.load().then(() => {
  //     if (navigator.geolocation) {
  //       let _self = this;

  //       let geocoder = new google.maps.Geocoder;
  //       let latlng = {
  //         lat: this.businessProfile.businesslat!,
  //         lng: this.businessProfile.businesslng!
  //       };
  //       geocoder.geocode({
  //         'location': latlng
  //       }, function (results) {
  //         if (results && results[0]) {
  //           let addrCtrl = <HTMLInputElement>document.getElementById("txt_address");

  //           _self.latitude = results[0].geometry.location.lat();
  //           _self.longitude = results[0].geometry.location.lng();
  //           _self.currentLocation = results[0].formatted_address;
  //           addrCtrl.value = _self.businessProfile.businessaddress!;
  //           let splitBy = _self.currentLocation.includes('-') ? '-' : ','
  //           let addr = _self.currentLocation.split(splitBy);
  //           _self.city = addr[addr.length - 2].trim();
  //           _self.country = addr[addr.length - 1].trim();
  //           _self.zoom = 16;
  //           // console.log(results);
  //         } else {
  //           console.log('Not found');
  //         }
  //       });
  //     }
  //   });
  // }

  onMapClicked(event: any) {
    // console.table(event);
    // this.latitude = event.coords.lat;
    // this.longitude = event.coords.lng;

    // let _self = this;

    // this.apiloader.load().then(() => {
    //   let geocoder = new google.maps.Geocoder;
    //   let latlng = {
    //     lat: this.latitude,
    //     lng: this.longitude
    //   };
    //   geocoder.geocode({
    //     'location': latlng
    //   }, function (results) {
    //     if (results && results[0]) {
    //       let addrCtrl = <HTMLInputElement>document.getElementById("txt_address");

    //       _self.latitude = results[0].geometry.location.lat();
    //       _self.longitude = results[0].geometry.location.lng();
    //       _self.currentLocation = results[0].formatted_address;
    //       addrCtrl.value = _self.currentLocation;
    //       let splitBy = _self.currentLocation.includes('-') ? '-' : ','
    //       let addr = _self.currentLocation.split(splitBy);
    //       _self.city = addr[addr.length - 2].trim();
    //       _self.country = addr[addr.length - 1].trim();
    //       _self.zoom = 16;
    //       // console.log(results);
    //     } else {
    //       console.log('Not found');
    //     }
    //   });
    // });
  }

  private makeRandom(lengthOfCode: number) {
    let possible = "AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890";

    let text = "";
    for (let i = 0; i < lengthOfCode; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }


  timeFix(time: string | null | undefined) {
    var result = null;

    if (time && time != null) {
      var ampm = time.split(" ")[1];
      var timeString = time.split(" ")[0];

      if (ampm == "PM") {
        var hour = timeString.split(":")[0] == "12"
          ? "12"
          : (parseInt(timeString.split(":")[0]) + 12).toString();

        result = hour + ":" + timeString.split(":")[1] + ":00";
      } else {
        var hour = timeString.split(":")[0] == "12" ? "00" : timeString.split(":")[0];
        result = hour + ":" + timeString.split(":")[1] + ":00";
      }
    }

    return result;
  }

  async save() {
    this.validateAll();

    if (this.isValid) {
      document.querySelector(".only-lock")?.removeAttribute("style");

      let data: any = this.businessProfile;

      var wHs: any[] = [];
      for (var wh of this.busWorkHours) {
        wHs.push({
          "dayofweek": wh!.dayofweek,
          "starttime": wh!.isweekend == 1 ? null : this.timeFix(wh!.starttime),
          "closetime": wh!.isweekend == 1 ? null : this.timeFix(wh!.closetime),
          "breakstarttime":
            wh!.isweekend == 1 ? null : this.timeFix(wh!.breakstarttime),
          "breakendtime": wh!.isweekend == 1 ? null : this.timeFix(wh!.breakendtime),
          "isweekend": wh!.isweekend
        });
      }

      data["businessworkinghours"] = wHs;
      data["businesscategories"] = this.selectedCatrgories;
      data["businessbrands"] = this.brandSelectedItems;

      if (data["businessimagepath2"] && data["businessimagepath2"].indexOf("null") >= 0)
        data["businessimagepath2"] = null;
      if (data["businessimagepath3"] && data["businessimagepath3"].indexOf("null") >= 0)
        data["businessimagepath3"] = null;
      if (data["businessimagepath4"] && data["businessimagepath4"].indexOf("null") >= 0)
        data["businessimagepath4"] = null;

      let bus = await this.businessProfileService.updateBusinessprofileAsync(data);

      if (this.files && this.files.length > 0) {
        this.fileUploadService.awsUpload(this.files, this.businessProfile.businessid).pipe(
          catchError((error: any) => {
            debugger
            // Handle the error here
            console.error('An error occurred:', error);
            // You can also return a new observable or throw a custom error here if needed
            return throwError(() => 'Faild to upload Business Images, try edit Business and upload it again.');
          })
        ).subscribe({
          next: async (event: any) => {
            if (this.currentUser.isadmin) {
              await this.businessProfileService.approveBusinessAsync({ "businessid": this.businessid, "businessstatus": "1" });
            }

            document.querySelector(".only-lock")?.setAttribute("style", "display: none;");

            window.location.href = "business/services";
          }, error: async (error) => {
            debugger
            if (this.currentUser.isadmin) {
              await this.businessProfileService.approveBusinessAsync({ "businessid": this.businessid, "businessstatus": "1" });
            }

            document.querySelector(".only-lock")?.setAttribute("style", "display: none;");

            alert('Faild to upload Business Images, try edit Business and upload it again.');

            window.location.href = "business/services";
          }
        });
      }
      else {
        if (this.currentUser.isadmin) {
          await this.businessProfileService.approveBusinessAsync({ "businessid": this.businessid, "businessstatus": "1" });
        }

        document.querySelector(".only-lock")?.setAttribute("style", "display: none;");

        window.location.href = "business/services";
      }
    } else {
      this.isValid = true;
      return;
    }
  }

  randomQueryParam = `?v=${Math.random().toString(36).substring(7)}`;

  getCategoryImage(name: string, color: string) {
    let floderName = "";
    if (this.businessactivity == 1)
      floderName = "autoservices";
    else if (this.businessactivity == 2)
      floderName = "autospareparts";

    if (color.trim() != "") {
      let imageName = name.substring(0, name.lastIndexOf('.'))
      return `${floderName}/${imageName} - ${color}.svg${this.randomQueryParam}`;
    } else
      return `${floderName}/${name}${this.randomQueryParam}`;
  }
}

